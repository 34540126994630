import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: null,
};

export const postInfoDetails = createSlice({
    name: 'postInfoDetail',
    initialState,
    reducers: {
        postInfoDetail: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { postInfoDetail } = postInfoDetails.actions;

export default postInfoDetails.reducer;