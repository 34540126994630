import { Box, Button } from "@mui/material";
import styles from './ErrorPage.module.css';
import React from "react";

const ErrorPage = () => {
    return (
        <Box className={styles.err_body}>
                <img
                    src={process.env.REACT_APP_IMAGE_URL + 'error-img.svg'}
                    className={styles.err_img}
                    alt='404'
                />
            <Box className={styles.err_container}>
            <Box className={styles.err_number}>404</Box>
            <Box className={styles.err_text}>Sorry, the page you're looking for cannot be<br/> found.</Box>
            <Button variant="contained" className={styles.err_btn} onClick={() => window.open('https://centilio.com', '_self')}>Go back to the App</Button>
            </Box>
        </Box>
    )
};

export default ErrorPage;