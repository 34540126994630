import {createSlice} from '@reduxjs/toolkit';

const initialState = {
 isLoading: false,
};

export const loaderReducer = createSlice({
 name: 'loader',
 initialState,
 reducers: {
     toggleLoader: (state, action) => {
   state.isLoading = action.payload;
  },
 },
});

export const {toggleLoader} = loaderReducer.actions;

export default loaderReducer.reducer;
