import { useDispatch } from 'react-redux';
import { toggleLoader } from '../../Store/loader/loaderReducer';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getInit, getUstat } from '../../Api/UserInfoGetApi';
import { orgInfoAction } from '../../Store/OrgInfo/orgInfo_action';
import { getPathFromLocalStroage } from '../../utils';

const OrgInIt = ({ isFetched, prePath }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orgId = prePath?.split('/')[1]?.split('c')[1];

 
  useEffect(() => {
    fetchSignInit();
  }, []);

  const fetchSignInit = () => {
    let param = {};
    dispatch(toggleLoader(true));
    getUstat(param, (res) => {
      if (res?.data) {
        handleInitHandler();
      } else {
        window.open(process.env.REACT_APP_SIGUP, "_self");
      }
    }, (err) => {
      toast.error(err.message);
      dispatch(toggleLoader(false));
    });
  };

  const handleInitHandler = () => {
    let param = orgId ? { org_id: orgId } : {};
    dispatch(orgInfoAction(null));
    getInit(param, (response) => {
      let data = response?.data;
      dispatch(orgInfoAction(data));
      dispatch(toggleLoader(false));
      isFetched(true);
      const id = data?.default_organizations?.Id ? `c${data?.default_organizations?.Id}` : '';
      let url_ = prePath?.replace(prePath?.split('/')[1], id);
      const pathLocal = getPathFromLocalStroage();
      if (prePath === '/') {
        { pathLocal.path ? navigate(`${id}${pathLocal.path}`) : navigate(`${id}/post/drafts`); }
      } else {
        navigate(url_);
      };
    }, (e) => {
      dispatch(toggleLoader(false));
      if (e?.response?.data?.error?.status === 403 && e?.response?.data?.error?.message === 'feature_access_denied') {
        navigate('/error-404');
      } else {
        toast.error(e.message);
      }
    });
  };
};

export default OrgInIt;