import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
// import './global_classes.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material';
import store from './Store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme(
  {
    typography: {
      fontFamily: 'var(--primary-font-family)',
    },
  }
);

root.render(
  <React.Fragment>
    <HashRouter>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </HashRouter>
  </React.Fragment>
);

reportWebVitals();
