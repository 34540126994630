import axios from "axios";



export const getAdminInit = ( resp, error) => {
    axios.get(process.env.REACT_APP_ADMIN_INIT, { withCredentials: process.env.REACT_APP_CREDENTIALS, })
        .then((res) => {
            resp(res);
        })
        .catch((err) => {
            error(err);
        });
};