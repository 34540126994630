import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react'
import './Integration.css';
import { getChannelConnect } from '../../Api/UploadChannel';
import { useDispatch, useSelector } from "react-redux";
import { toggleLoader } from '../../Store/loader/loaderReducer';
import { toast } from 'react-toastify';

const Integration = () => {
    const org_info = useSelector((state) => state?.org_info?.value);
    const org_id = org_info?.default_organizations?.Id;
    const json_Features = JSON.parse(org_info.show_features);
    const dispatch = useDispatch();

    const wixFeatures = json_Features[0].features.WIX_INTEGRATION === undefined ? true : json_Features[0].features.WIX_INTEGRATION

    const initialState = [
        { name: 'WIX', channel: 1, describe: 'Upload documents directly from WIX to here.', img: 'Integrations/wix.svg' },
        { name: 'Zoho CRM', channel: 3, describe: 'Upload documents directly from Zoho CRM to here.', img: 'Integrations/zoho.svg' },
    ];

    const [integration, setIntegration] = useState(initialState)

    const handleConnect = (item) => {
        let options = { org_id: org_id, channel_type: item.channel };
        if(item.channel===3){
            getChannelConnect(options, (res) => {
                dispatch(toggleLoader(false));
                window.open(res.data.auth_url, "_blank");
                // console.log('childWin',childWin);
                // let userAgent = navigator.userAgent;
                // let winClosed = setInterval(function () {
                //     if (childWin.closed) {
                //         clearInterval(winClosed);
                //         handleChannelType(options, type, re_connect);
                //         setIs_closed(false);
                //     }
                //     else if (childWin.length === 0 && !(userAgent.indexOf("Firefox") !== -1)) {
                //         if (childWin.location.href.includes('callback')) {
                //             childWin.close()
                //         }
                //     } else if (childWin.length === 0 && userAgent.indexOf("Firefox") !== -1) {
                //     }
                // }, 250);
            }, (err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            });
        }
    }
    const filteredintegration = integration.filter(item => wixFeatures || item.name !== 'WIX');
   
    return (
        <Box>
            {filteredintegration?.map((item, index) => <Box className='integration_body' key={index}>
               { <Box sx={{ padding: '20px !important', position: 'relative' }}>
                    <Box className='integration_box'>
                        <img
                            src={process.env.REACT_APP_IMAGE_URL + item?.img}
                            className='dropbox-img'
                            alt='dropbox'
                        />

                        <Button variant="outlined" className="box-connect" onClick={() => { handleConnect(item) }} >Connect</Button>
                    </Box>
                    <Box className="integration_text">
                        <Typography className="top_text">
                            {item?.name}
                        </Typography>
                        <Typography className="bottom_text">
                            {item?.describe}
                        </Typography>
                    </Box>
                </Box> }
            </Box>)}
        </Box>
    )
}

export default Integration