import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: null,
};

export const AdminInfoDetails = createSlice({
    name: 'AdminInfoDetail',
    initialState,
    reducers: {
        AdminInfoDetail: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { AdminInfoDetail } = AdminInfoDetails.actions;

export default AdminInfoDetails.reducer;