import { Box, Tab, Tabs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import styles from './AdminTool.module.css';
import AiPrompt from './AiPrompt/AiPrompt';
import FeatureAllow from './FeatureAllow/FeatureAllow';
import { getAdminInit } from '../../Api/AdminGetApi';
import { useDispatch, useSelector } from 'react-redux';
import { AdminInfoAction } from '../../Store/AdminInfo/adminInfoAction';
const AdminTool = () => {
    const [tabvalue, setTabValue] = useState(0);
    const org_info = useSelector((state) => state?.org_info?.value);
    const org_id = org_info?.default_organizations?.Id;
    const dispatch = useDispatch();    


    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setTabValue(index);
    };

   

    useEffect(() => {
        getAdminInit((response) => {
            let data = response?.data;
            dispatch(AdminInfoAction(data));
        })
    }, [])



    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                className={styles.label_name}
                value={tabvalue}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
            >
                <Tab value={0} label='Ai Prompt' />
                <Tab value={1} label='Feature Allow' />

            </Tabs>
            <SwipeableViews
                index={tabvalue}
                onChangeIndex={handleChangeIndex}
                className={styles.label_name_swipe}
            >
                <AiPrompt />
                <FeatureAllow />
            </SwipeableViews >
        </Box>
    )
}

export default AdminTool