import { AdminInfoDetail } from './adminInfoReducer';

export const AdminInfoAction = (query) => async (dispatch) => {
    try {
        dispatch(AdminInfoDetail(query));
    } catch (err) {
        const error = {
            code: 0,
            message: typeof err == 'object' ? err.message : err,
        };
        throw error;
    }
};