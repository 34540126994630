import AppRoute from './appRoute';
import { Box, ThemeProvider } from '@mui/material';
import { theme } from './theme';
import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  const CloseButton = ({ closeToast }) => (
    <Box
      className="close_icons"
      onClick={closeToast}
    >
      <CloseIcon fontSize="small" />
    </Box>
  );

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        closeButton={CloseButton}
        pauseOnFocusLoss
        icon={false}
        draggable
        pauseOnHover={true}
        theme='light'
      />
      <ThemeProvider theme={theme}>
        <AppRoute />
      </ThemeProvider >
    </>
  );
}

export default App;
