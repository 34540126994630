import { postInfoDetail } from './postInfo_reducer';

export const postInfoAction = (query) => async (dispatch) => {
    try {
        await dispatch(postInfoDetail(query));
    } catch (err) {
        const error = {
            code: 0,
            message: typeof err == 'object' ? err.message : err,
        };
        throw error;
    }
};