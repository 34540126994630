import moment from "moment";

export const formatTimeAgo = (date) => {

    
    const momentDate = moment(date, "DD/MM/YYYY hh:mm A");
    const timestamp = momentDate.valueOf(); 


    const now = Date.now();
    const secondsPast = Math.floor((now - timestamp) / 1000);

    if (secondsPast < 60) {
        return `${secondsPast} sec ago`;
    } else if (secondsPast < 3600) {
        const minutes = Math.floor(secondsPast / 60);
        return `${minutes} min ago`;
    } else if (secondsPast < 86400) {
        const hours = Math.floor(secondsPast / 3600);
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (secondsPast < 604800) {
        const days = Math.floor(secondsPast / 86400);
        return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (secondsPast < 691200) {
        return `7 days ago`;
    } else {
        const date = new Date(timestamp);
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        return `${formattedDate}`;
    }
};

export const getPathFromLocalStroage = () => {
    let pathValue = localStorage.getItem("path");
    let path = pathValue ? JSON.parse(pathValue) : { value: 0 };
    return path;
}


export const areValuesEmpty = (obj) => {
    if (!obj.date) {
        delete obj.date;
    }
    return Object.values(obj).every(value => value !== "");
}
