import * as React from 'react';
import { Box, Button } from "@mui/material"
import styles from './Authorize.module.css';


const Authorize = ({ steps }) => {
    const handlePrevious = () => {
        steps(0);
    }
    return (
        <Box>
            <Box className={styles.authorize_heading}>Authorize Centilio Connector app in Zoho CRM</Box>
            <Box className={styles.authorize_phara}>Click this button to grant Centilio Connector permissions to add Google Analytics information to your Leads and Contacts:</Box>
            <Box className={styles.authorize_button_div} >
                <Button className={styles.authorize_button} variant="contained" color="error">Authorize & Finish</Button>
            </Box>
            <Box className={styles.authorize_note} >Make sure to login Zoho CRM as an admin first</Box>
            <Box className={styles.authorize_previous_button_div} >
                <Button  className={styles.authorize_previous_button} variant="text" color="error" onClick={() => handlePrevious()}>Previous</Button>
            </Box>
        </Box>
    )
}
export default Authorize;