import { Box, Button, TextField } from '@mui/material'
import axios from 'axios'
import JoditEditor from 'jodit-react'
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { toggleLoader } from '../../Store/loader/loaderReducer'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
// import styles from "../Editor/Editor.module.css";
import './ViewPage.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getPathFromLocalStroage } from '../../utils'


const config = {
  "toolbar": false,
  "readonly": true,
  "showCharsCounter": false,
  "showWordsCounter": false,
  "showXPathInStatusbar": false,
  'disablePlugins': 'add-new-line',
  'autoresize': false,
  "allowResizeY": false,
  "buttons": "bold,italic,underline,strikethrough,eraser,ul,ol,font,fontsize,paragraph,lineHeight,indent,outdent,left,undo,redo"
};

const ViewPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathLocal = getPathFromLocalStroage();

  const editorRef1 = useRef(null);
  const joditWrapperRef = useRef(null);
  const textAreaRef = useRef(null);

  const org_info = useSelector((state) => state?.org_info?.value);
  const org_id = org_info?.default_organizations?.Id;

  const [value, setValues] = useState();


  useEffect(() => {
    let data = {
      org_id: org_id,
      post_id: id,
    };

    dispatch(toggleLoader(true));
    axios
      .post(process.env.REACT_APP_VIEWPOST, data, {
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        setValues(res.data.data)
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  }, [])

  return (
    <Box
      className='view'
      sx={{
        padding: '0px 60px 0px 60px',
        height: "100%",
        position: 'relative'
      }}
      ref={joditWrapperRef}
    >

      <Box sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
        <Button variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(`/c${org_id}${pathLocal.path}`)}
        > Back
        </Button>
      </Box>
      <Box
        sx={{
          backgroundColor: '#fff', fontSize: '24px', padding: '20px 5px',
          "& fieldset": { border: 'none' },
          "& .MuiOutlinedInput-input": { fontSize: '24px' },
          "& .Mui-disabled textarea": {
            WebkitTextFillColor: 'black', fontSize: '24px',
            fontWeight: '400'
          },

        }}>{value?.Title}</Box>
      <JoditEditor
        ref={editorRef1}
        value={value?.Content}
        config={config}
      // onBlur={(e) => setSchedule({ ...schedule, content: e })}
      />
    </Box>
  )
}

export default ViewPage