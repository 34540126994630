import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import * as Pages from './Pages/index';
import NavBar from './Component/NavBar/NavBar';
import TopBar from './Component/TopBar/TopBar';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import './global.css';

const AppRoute = () => {


    const [isInitFetched, setIsInitFetched] = useState(false);
    const [toggle, setToggle] = useState(null);
    const [prePath, setPrePath] = useState('/');
    const [editor, setEditor] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const loader = useSelector((state) => state?.loader);
    const org_info = useSelector((state) => state?.org_info?.value);
    let reload = window.performance.getEntriesByType('navigation').map((nav) => nav.type);


    let enabled_init = ['post', 'settings'];
    let parts = location.pathname.split('/');

    let path = ['scheduled', 'drafts', 'published', 'pending-review', 'failed', 'trash']
    let commonValues = parts.find(value => path.includes(value));

    const FindPath = path.includes(commonValues)

   

    useEffect(() => {

        if (FindPath) {
            setEditor(commonValues)
        }

    }, [location])


    useEffect(() => {
        if (reload[0] === 'reload' && location.pathname !== '/error-404' && location.pathname !== '/admintool') {

            navigate('/');
            setPrePath(location.pathname);
        } else {
            if (reload[0] === 'reload' && location.pathname === '/error-404') navigate('/');
        }
    }, []);

    useEffect(() => {
        if (!isInitFetched && enabled_init?.indexOf(location?.pathname?.split('/')[2]) !== -1) {
            setPrePath(location.pathname);
            navigate('/');
        }
    }, []);

    return (
        <>
            {loader.isLoading && (
                <Box sx={{ width: "100%", height: "2px", top: 0, zIndex: 4, position: 'absolute' }}>
                    <LinearProgress value={0} valueBuffer={10} />
                </Box>
            )}
            <Box className='main_wrap'>
                <NavBar isFetched={isInitFetched} expand={toggle} />
                <Box className='main_wrapper'>
                    <TopBar isFetched={isInitFetched} expand={toggle} setIsNavExpand={setToggle} />
                    <Box className='main_content'>
                        <Routes>
                            <Route path='/' exact element={<Pages.OrgInit prePath={prePath} isFetched={(fetched) => setIsInitFetched(fetched)} />} />
                            <Route path="/admintool" element={<Pages.AdminTool />} />

                            <Route path=":id">
                                <Route path="post/:type" index element={isInitFetched ? <Pages.Home /> : null} />
                                <Route path="settings" element={isInitFetched ? <Pages.Settings /> : null} />
                                <Route path="Connector" element={isInitFetched ? <Pages.Connector /> : null} />
                                <Route path="Integration" element={isInitFetched ? <Pages.Integration /> : null} />
                                <Route path="upload" element={isInitFetched ? <Pages.Upload /> : null} />
                                {editor && <Route path={`post/:${editor}/:id`} element={isInitFetched ? <Pages.Editor /> : null} />}
                                {editor && <Route path={`post/:${editor}/:id/view`} element={isInitFetched ? <Pages.ViewPage /> : null} />}
                            </Route>
                            <Route path="/*" element={<Navigate to='/error-404' />} />
                            <Route path="/error-404" element={<Pages.ErrorPage />} />
                        </Routes>
                    </Box>
                </Box>
            </Box >
        </>
    )
};

export default AppRoute;