import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const OrgNavigation = () => {
    const navigate = useNavigate();

    const org_info = useSelector((state) => state?.org_info?.value);

    const navigateHandle = (path, state) => {
        const finalPath = '/c' + org_info?.default_organizations?.Id + path;
        navigate(finalPath, state && { state: state });
    };

    return navigateHandle;
};