import { Autocomplete, Box, Button, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Skeleton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from './Settings.module.css';
import { useDispatch, useSelector } from "react-redux";
import { toggleLoader } from "../../Store/loader/loaderReducer";
import axios from "axios";
import { toast } from "react-toastify";
import moment from 'moment-timezone';
import Country from "../../Utils/timeZone";

const Settings = () => {
    const [apiKey, setApiKey] = useState({ api_key: '', member_id: '', site_id: '', timezone: moment.tz.guess(), channel_type: '' });
    const [editApiKey, setEditApiKey] = useState(null);
    const [channel_type, setChannel_type] = useState(false);
    const [pageShow, setPageShow] = useState(false);

    const timezones = Country();
    const dispatch = useDispatch();

    const org_info = useSelector((state) => state?.org_info?.value);
    const json_Features = JSON.parse(org_info.show_features);
    const FeaturesAllTypes = json_Features[0].features
    const features = FeaturesAllTypes.CENTILIO_INTEGRATION_SETTING === undefined ? true : FeaturesAllTypes.CENTILIO_INTEGRATION_SETTING;


    useEffect(() => {
        fetchSettings();
    }, []);



    const fetchSettings = () => {
        axios.get(process.env.REACT_APP_SETTINGS, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: { org_id: org_info?.default_organizations?.Id } })
            .then((res) => {
                dispatch(toggleLoader(false));
                setPageShow(true);

                let data = res.data.setting;
                let obj = { ...apiKey };
                if (data) {
                    obj['api_key'] = data?.Api_Key;
                    obj['member_id'] = data?.Member_Id;
                    obj['site_id'] = data?.Site_Id;
                    obj['channel_type'] = data?.Channel_Type;
                    if (data?.timezone) obj['timezone'] = data?.timezone;
                    setApiKey(obj);
                    setEditApiKey(obj);
                };
            })
            .catch((err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            });
    };


    const getEditInputField = (values) => {
        let old_obj = Object.keys(editApiKey)?.find(ele => values[ele] !== editApiKey[ele]);
        return old_obj;
    };

    const channelType = (e) => {
        axios.put(process.env.REACT_APP_SETTINGS, { channel_type: e, org_id: org_info?.default_organizations?.Id }, { withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                dispatch(toggleLoader(false));
            })
            .catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.message);
            });
    }

    const handlePost = () => {
        let params = { org_id: org_info?.default_organizations?.Id, api_key: apiKey?.api_key, member_id: apiKey?.member_id, site_id: apiKey?.site_id, timezone: apiKey?.timezone, channel_type: apiKey?.channel_type };

        if (!apiKey?.timezone) {
            toast.warning('timezone required');
            return;
        }

        let edit_value = getEditInputField(apiKey);
        if (!edit_value) {
            toast.warning('No changes');
            return;
        };

        let update_value = {};
        Object.entries(editApiKey).forEach(([key, value]) => {
            if (value !== apiKey[key]) {
                update_value[key] = apiKey[key];
            };
        });

        dispatch(toggleLoader(true));
        if (update_value && Object.keys(update_value)?.length) {
            axios.put(process.env.REACT_APP_SETTINGS, { ...update_value, org_id: org_info?.default_organizations?.Id }, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                .then((res) => {
                    dispatch(toggleLoader(false));
                    toast.success('Success');
                })
                .catch((err) => {
                    dispatch(toggleLoader(false));
                    toast.error(err.message);
                });
        } else {
            axios.post(process.env.REACT_APP_SETTINGS, params, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                .then((res) => {
                    dispatch(toggleLoader(false));
                    toast.success('Success');
                })
                .catch((err) => {
                    dispatch(toggleLoader(false));
                    if (err?.response?.data?.error?.message === 'duplicate_record') toast.error(err?.response?.data?.error?.details);
                    else toast.error(err.message);
                });
        }
    };


    return (
        <>
            {pageShow ?
                <Box>
                    <Box className={styles.header_title}>
                        <Box className={styles.title}>
                            <Box className={styles.header_text}>Settings</Box>
                        </Box>
                    </Box>
                    {<Box sx={{ padding: '0px 30px' }}>
                        <FormControl sx={{ display: 'flex', flexDirection: "row" }}>
                            <FormLabel id="demo-radio-buttons-group-label" style={{ display: 'flex', alignItems: 'center', color: 'var(--table-font-color)!important' }}>Blog Platform</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={apiKey.channel_type}
                                name="radio-buttons-group"
                                sx={{ display: "flex", flexDirection: "row", padding: '0px 30px' }}
                                onChange={(e) => setApiKey({ ...apiKey, channel_type: Number(e.target.value) })}

                            >
                                <FormControlLabel value={1} onClick={(e) => channelType(e.target.value)} control={<Radio />} label="Wix" />
                                {features && <FormControlLabel value={2} onClick={(e) => channelType(e.target.value)} control={<Radio />} label="Centilio" />}
                            </RadioGroup>
                        </FormControl>
                    </Box>}
                    {apiKey.channel_type === 1 && <Box className={styles.main_wrap}>
                        <Box className={styles.settings_body}>
                            <Box className={styles.wix_setting}>Wix Setting</Box>
                            <Box className={styles.wix_list} sx={{ padding: '20px' }}>
                                <Box className={styles.file_post_container}>
                                    <InputLabel className={styles.post_label}>Api Key</InputLabel>
                                    <TextField
                                        multiline
                                        minRows={6}
                                        className={styles.file_post_input}
                                        onChange={(e) => setApiKey({ ...apiKey, api_key: e.target.value })}
                                        sx={{
                                            fieldset: { border: "1px solid #E6E7E9" },
                                            '& .MuiOutlinedInput-root': {
                                                '&:hover fieldset': {
                                                    borderColor: 'var(--primary) !important',
                                                },
                                                '& fieldset.MuiOutlinedInput-notchedOutline': {
                                                    border: "1px solid #E6E7E9"
                                                },
                                                fontSize: '14px',
                                                // paddingLeft: '2px',
                                            },
                                        }}
                                        value={apiKey?.api_key}
                                        placeholder='Enter Api Key'
                                    />
                                </Box>
                                <Box className={styles.file_post_container}>
                                    <InputLabel className={styles.post_label}>Member Id</InputLabel>
                                    <TextField
                                        className={styles.file_post_input}
                                        onChange={(e) => setApiKey({ ...apiKey, member_id: e.target.value })}
                                        sx={{
                                            fieldset: { border: "1px solid #E6E7E9" },
                                            '& .MuiOutlinedInput-root': {
                                                '&:hover fieldset': {
                                                    borderColor: 'var(--primary) !important',
                                                },
                                                '& fieldset.MuiOutlinedInput-notchedOutline': {
                                                    border: "1px solid #E6E7E9"
                                                },
                                                fontSize: '14px',
                                                paddingLeft: '2px',
                                                height: '41.92px'
                                            },
                                        }}
                                        value={apiKey?.member_id}
                                        placeholder='Enter Member Id'
                                    />
                                </Box>
                                <Box className={styles.file_post_container}>
                                    <InputLabel className={styles.post_label}>Site Id</InputLabel>
                                    <TextField
                                        className={styles.file_post_input}
                                        onChange={(e) => setApiKey({ ...apiKey, site_id: e.target.value })}
                                        sx={{
                                            fieldset: { border: "1px solid #E6E7E9" },
                                            '& .MuiOutlinedInput-root': {
                                                '&:hover fieldset': {
                                                    borderColor: 'var(--primary) !important',
                                                },
                                                '& fieldset.MuiOutlinedInput-notchedOutline': {
                                                    border: "1px solid #E6E7E9"
                                                },
                                                fontSize: '14px',
                                                paddingLeft: '2px',
                                                height: '41.92px'
                                            },
                                        }}
                                        value={apiKey?.site_id}
                                        placeholder='Enter Site Id'
                                    />
                                </Box>
                                <Box className={styles.file_post_container}>
                                    <InputLabel className={styles.post_label}>Time Zone</InputLabel>
                                    <Autocomplete
                                        sx={{
                                            fieldset: { border: "1px solid #E6E7E9" },
                                            '&:hover': {
                                                '&& fieldset': {
                                                    border: '1px solid var(--primary) !important',
                                                    outline: 0,
                                                },
                                            },
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": { border: '1px solid var(--primary) !important' },
                                            fontSize: '14px',
                                            "& .MuiInputBase-root": { height: "41.92px" },
                                            width: '85%',
                                        }}
                                        options={timezones}
                                        autoHighlight
                                        getOptionLabel={(option) => option}
                                        renderOption={(props, option) => {
                                            const { key, ...otherProps } = props;
                                            return (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, fontSize: '14px', fontFamily: 'var(--primary-font-family)' }} key={key} {...otherProps}>
                                                    {option}
                                                </Box>
                                            )
                                        }}
                                        onChange={(event, newValue) => {
                                            setApiKey({ ...apiKey, timezone: newValue })
                                        }}
                                        value={apiKey?.timezone}
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{ "& .MuiInputBase-root": { height: "41.92px", padding: '0px 0px 0px 10px !important', fontSize: '14px' } }}
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                                <Box className={styles.btn_container}>
                                    <Button variant='contained' className={styles.review_btn} onClick={handlePost}>Submit</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>}
                </Box> : <Skeleton sx={{ bgcolor: 'rgba(189,197,209,.2)', margin: '10px' }} animation="wave" variant="rounded" width={'calc(100% - 20px)'} height={'calc(100vh - 80px)'} />}
        </>
    );
};

export default Settings;